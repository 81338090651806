import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import ContentfulRichText from '../components/ContentfulRichText'
import {
  ContentfulHero,
  ContentfulPage,
  ContentfulText,
} from '../../types/graphql-types'
import Layout from '../components/Layout/Layout'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

const HeroStyled = styled.div`
  h1 {
    ${tw`leading-none mb-4`}
  }
  p {
    ${tw`text-xl md:text-2xl mb-0`}
  }
`

const SectionContent = styled.section`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`mb-4`}
  }
`

export const query = graphql`
  query StyleGuide {
    contentfulPage(externalName: { eq: "Style Guide Page" }) {
      title
      externalName
      seoTitle
      seoDescription
      noindex
      references {
        ...hero
      }
      content {
        raw
      }
    }
  }
`

const StyleGuidePage: React.FC<Props> = ({ data }: Props) => {
  return (
    <Layout>
      <SEO
        title={data.contentfulPage?.seoTitle as string}
        description={data.contentfulPage?.seoDescription as string}
        noIndex={data.contentfulPage?.noindex as boolean}
      />

      {data.contentfulPage?.references?.map(content => {
        const hero = content as ContentfulHero

        if (hero?.externalName === 'Style Guide Hero') {
          return (
            <HeroStyled
              key={hero.id}
              className={`gradient-3-bby mx-auto max-w-1920 md:h-64`}
            >
              <div className="flex h-full flex-wrap content-center">
                <div className={`container py-12 text-left text-white`}>
                  {hero.references?.map(heroCopy => {
                    const text = heroCopy as ContentfulText
                    if (text?.externalName === 'Hero Copy') {
                      return (
                        <React.Fragment key={text.id}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: text?.longSimpleText?.childMarkdownRemark
                                ?.html as string,
                            }}
                          ></div>
                        </React.Fragment>
                      )
                    }
                  })}
                </div>
              </div>
            </HeroStyled>
          )
        }
      })}

      <SectionContent className="content-section pt-8">
        <ContentfulRichText document={data.contentfulPage?.content} />
      </SectionContent>
    </Layout>
  )
}

export default StyleGuidePage
